<template>
  <div v-loading="loading">
    <el-select
      v-model="field.selected_filter"
      @change="updateEntityData"
      clearable
      filterable
      :placeholder="fieldPlaceholder"
      :size="size == 'mini' ? 'mini' : 'large'"
      :multiple="isMultiple ? true : false"
      collapse-tags
    >
      <el-option
        v-for="(temp, i) in allEntityDataList"
        :key="temp._id + '_' + i"
        :value="temp._id"
        :label="getLabel(temp, i)"
        >{{ getLabel(temp, i) }}
      </el-option>
    </el-select>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { postAPICall } from "@/helpers/httpHelper";
import CustomDashboardConfig from "@/mixins/CustomDashboardHelper.js";
import AutomationHelper from "@/mixins/AutomationHelper";
export default {
  props: ["field", "fieldPlaceholder", "size", "isMultiple", "filters","primary_fields"],
  computed: {
    ...mapGetters("entities", [
      // "getEntityDataByEntityId",
      // "getEntityById",
      // "getEntityRecordsForTable",
      // "getEntitiesDataErrors",
    ]),
  },
  mixins:[
  CustomDashboardConfig,
  AutomationHelper,
  ],
  watch: {
    async field() {
      this.fetchEntityById(this.field.filter_field_entity_id);
    },
  },
  data() {
    return {
      currentEntity: null,
      loading: false,
      allEntityDataList: [],
    };
  },
  async mounted() {
    this.loading = true;
    await this.fetchEntityById(this.field.filter_field_entity_id);
    this.loading = false;
  },
  methods: {
    updateEntityData(value) {
      this.$emit("entityFilterUpdated", {
        entity_id: this.field.entity_id,
        value: value,
        filter: this.field,
      });
    },
    async fetchEntityById(entityId) {
      this.fetchEntitiesDataForTable(entityId);

      // await this.$store.dispatch("entities/fetchEntityByIdForEntityField", {
      //   entity_id: entityId,
      // });
      // if (this.getEntityById) {
      //   this.currentEntity = this.getEntityById;
      //   this.fetchEntitiesDataForTable(entityId);
      // } else {
      //   this.loading = false;
      // }
    },
    async fetchEntitiesDataForTable(entityId) {
      this.loading = true;
      let params = {
        entity_id: entityId,
        template_fields_data: [],
        filters: this.filters || [],
      };
      // await this.$store.dispatch("entities/fetchEntityRecordsForTable", params);
      const getEntityRecordsForTable = await postAPICall(
        "POST",
        "/entities-data/entity/data",
        params
      );
      if (getEntityRecordsForTable) {
        this.allEntityDataList = getEntityRecordsForTable.data;
        this.currentEntity = getEntityRecordsForTable.selectedEntity;
        this.allEntityFields = this.getAllFieldsFromEntity(
          this.currentEntity,
          false,
          false,
          false,
        );
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    getField(key) {
      if (key && this.allEntityFields && this.allEntityFields.length) {
        return this.allEntityFields.find((e) => e.key == key);
      }
      return null;
    },
    getLabel(item, i) {
      let selectedEntity = this.currentEntity ? this.currentEntity : {};
      let name = "data " + i;
      if(this.primary_fields && this.primary_fields.length){
        name = "";
        this.primary_fields.forEach((pr, index) => {
          let field = this.getField(pr)
          if (
          field &&
          index < 3 &&
          this.getPrimaryName(field, item)
        ) {
          let separator = "";
          if (index != 0) {
            separator = " - ";
          }
          name = name + separator + this.getPrimaryName(field, item);
        }
        })
      }
      else if (selectedEntity && selectedEntity.primaryFields) {
        let primaryField = selectedEntity.primaryFields[0];
        if (
          item?.entityData?.[primaryField?.template_id]?.[primaryField?.key]
        ) {
          name =
            primaryField.inputType == "ENTITY"
              ? item.entityData[primaryField.template_id][
                  primaryField.key + "/name"
                ]
              : primaryField.input_type == "DATE"
              ? moment(
                  item.entityData[primaryField.template_id][primaryField.key]
                ).format(this.getDefaultDateFormat)
              : primaryField.input_type == "DATE_TIME"
              ? moment(
                  item.entityData[primaryField.template_id][primaryField.key]
                ).format(this.getDefaultDateFormat + " HH:mm:ss")
              : item.entityData[primaryField.template_id][primaryField.key];
        }
      }
      return name;
    },
  },
};
</script>
